import React, { useMemo } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache
} from '@apollo/client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { setContext } from '@apollo/client/link/context'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { useAsyncEffect } from '@react-hook/async'
import styled from 'styled-components'

import Repositories from './Repositories'

const Session = props => {
  const { status, error, value: session } = useAsyncEffect(
    () => Auth.currentSession(),
    []
  )
  if (status !== 'success') {
    return 'LOADING'
  } else if (status === 'success') {
    return <Content session={session} />
  }
}

export default Session

const Content = props => {
  const state = JSON.parse(document.getElementById('state').textContent)
  const client = useMemo(
    () => {
      const httpLink = new BatchHttpLink({ uri: state.graphql })
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${props.session.accessToken.jwtToken}`
          }
        }
      })
      return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
      })
    },
    [props.session]
  )

  return (
    <ApolloProvider client={client}>
      <Header>
        <Title>Signal Releases</Title>
        <Menu><AmplifySignOut /></Menu>
      </Header>
      <Container>
        <Repositories />
      </Container>
    </ApolloProvider>
  )
}

const Header = styled.header`
  display: flex;
  background-color: grey;
`

const Title = styled.h1`
  flex: 1 1 auto;
  color: white;
`

const Menu = styled.div`
  flex: 0 0 auto; 
`

const Container = styled.section`
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
`
