import React from 'react'
import { List } from 'antd'
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  RocketTwoTone,
  StopTwoTone
} from '@ant-design/icons'
import { useMutation, useQuery, gql } from '@apollo/client'
import { filter, isNil, map, pipe } from 'ramda'

const Repositories = props => {
  const { loading, error, data } = useQuery(query)
  if (error) {
    return 'ERROR'
  } else if (loading) {
    return 'LOADING'
  } else {
    const repositories = pipe(
      map(repository => repository.name)
    )(data.repositories)
    return (
      <List
        size='large'
        bordered
        dataSource={repositories}
        renderItem={repository => (
          <Repository name={repository} />
        )}
      />
    )
  }
}

export default Repositories

const Repository = props => {
  const { loading, error, data } = useQuery(repositoryQuery, {
    variables: { name: props.name }
  })
  return (
    <List.Item>
      <div>{props.name}</div>
      <Status name={props.name} loading={loading} error={error} data={data} />
    </List.Item>
  )
}

const Status = props => {
  const [release, { loading, error }] = useMutation(releaseQuery, {
    refetchQueries: [{
      query: repositoryQuery,
      variables: { name: props.name }
    }],
    awaitRefetchQueries: true
  })
  if (loading || props.loading) {
    return <LoadingOutlined spin />
  } else if (error || props.error) {
    return <ExclamationCircleTwoTone twoToneColor='red' />
  } else if (props.data && !props.data.repository.release) {
    return <StopTwoTone twoToneColor='red' />
  } else if (props.data && props.data.repository.trunk.commitId === props.data.repository.release.commitId) {
    return <CheckCircleTwoTone twoToneColor='#52c41a' />
  } else {
    return (
      <RocketTwoTone
        onClick={() => release({
          variables: { repository: props.name }
        })}
        style={{
          cursor: 'pointer'
        }}
        twoToneColor='#eb2f96'
      />
    )
  }
}

const query = gql`
  query repositories {
    repositories {
      name
    }
  }
`

const repositoryQuery = gql`
  query repository ($name: String) {
    repository (name: $name) {
      name,
      trunk: mostRecentCommit(branch: "trunk") {
        commitId
        message
      },
      release: mostRecentCommit(branch: "release") {
        commitId
        message
      }
    }
  }
`

const releaseQuery = gql`
  mutation release ($repository: String) {
    release(repository: $repository)
  }
`
