import React from 'react'
import ReactDOM from 'react-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'
import Amplify from 'aws-amplify'
import 'antd/dist/antd.css'

import config from './config'
import Session from './Session'

Amplify.configure(config)

const App = withAuthenticator(Session)

ReactDOM.render(<App />, document.getElementById('app'))
